<template>
  <el-dialog v-model="dialogEditPostVisible$" :fullscreen="parseFloat(innerWidth) <= 768 ? true : false" @closed="closed" :close-on-press-escape="false" :close-on-click-modal="false" :title="$t('編輯')+$t('label.post')"
    :width="`${w}`">
    <hr>
    <div class="d-flex align-items-center">
      <div class="user-img">
        <img :src="user.avatar" alt="userimg" class="avatar-60 rounded-circle " />
      </div>
      <form class="post-text ms-3 w-100" action="javascript:void();">
        <textarea v-model="postForm.post_content" type="text" class="form-control rounded" :placeholder="$t('label.createPost')" style="border: none" rows="5" />
        </form>
          </div>
          <hr />

          <!-- 單篇解鎖新增上傳封面 -->
          <template v-if="isDonate">
            <div class="">
              <div>{{ $t('上傳封面') }}</div>
              <el-upload v-if="postForm.post_banner.length === 0 && !uploadBannerLoading" :show-file-list="false" :headers="{'authorization':token}"
                :accept="commomData.uploadOption.accept" :data=" { 'type': 'post_banner' }"
                :action="commomData.uploadOption.url" :on-success="handleBannerSuccess" :on-error="uploadFileError"
                :on-preview="handleBannerPreview" :on-remove="handleBannerRemove" :on-progress="handleBannerProgress"
                :before-upload="handleBeforeBannerUpload" :multiple="true">
                <el-tooltip class="item" effect="dark" :content="`${$t('label.uploadImage')}`" placement="bottom">
                  <li class="mb-md-0" style="list-style: none;">
                    <!-- <button type="button" class="btn btn-circle-sm mb-1 me-1">
                      <i class="far ri-image-line fa-fw me-0"></i>
                    </button> -->
                    <div class="upload_wrap">+</div>
                  </li>
                </el-tooltip>
              </el-upload>
              <!-- banner-->
              <div v-else-if="postForm.post_banner.length || uploadBannerLoading">
                <div>
                  <div>
                    <!-- {{message_template_media}} -->
                    <ul class="el-upload-list el-upload-list--picture-card">
                      <!-- upload Percent -->
                      <li v-for="(m,index) in postForm.post_banner" :key="index" class="el-upload-list__item is-success"
                        tabindex="0" style="width: 31.5%;height: 100%;min-width: 90px;">
                        <img class="el-upload-list__item-thumbnail" :src="m.file_url" alt="">
                        <label class="el-upload-list__item-status-label">
                          <i class="el-icon el-icon--upload-success el-icon--check">
                            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                              <path fill="currentColor"
                                d="M406.656 706.944 195.84 496.256a32 32 0 1 0-45.248 45.248l256 256 512-512a32 32 0 0 0-45.248-45.248L406.592 706.944z">
                              </path>
                            </svg>
                          </i>
                        </label>
                        <i class="el-icon el-icon--close">
                          <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor"
                              d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                            </path>
                          </svg>
                        </i>
                        <span class="el-upload-list__item-actions">
                          <span class="el-upload-list__item-preview">
                            <i @click="onBannerPreview(m)" class="el-icon el-icon--zoom-in">
                              <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                <path fill="currentColor"
                                  d="m795.904 750.72 124.992 124.928a32 32 0 0 1-45.248 45.248L750.656 795.904a416 416 0 1 1 45.248-45.248zM480 832a352 352 0 1 0 0-704 352 352 0 0 0 0 704zm-32-384v-96a32 32 0 0 1 64 0v96h96a32 32 0 0 1 0 64h-96v96a32 32 0 0 1-64 0v-96h-96a32 32 0 0 1 0-64h96z">
                                </path>
                              </svg>
                            </i>
                          </span>
                          <span @click="onBannerDelete(index)" class="el-upload-list__item-delete">
                            <i class="el-icon el-icon--delete">
                              <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                <path fill="currentColor"
                                  d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32zm192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32z">
                                </path>
                              </svg>
                            </i>
                          </span>
                        </span>
                      </li>
                      <li v-if="uploadBannerLoading" v-loading="uploadBannerLoading" class="el-upload-list__item is-success"
                        tabindex="0" style="width: 31.5%;height: 100%;min-width: 90px;">
                        <div class="text-primary text-center" style="font-size: 16px; z-index: 99999;">
                          <div class="p-1">{{uploadBannerPercent}}</div>
                        </div>
                      </li>
                    </ul>
                    <el-dialog v-model="dialogBannerVisible" width="80%">
                      <div v-if="dialogBannerType === 'video'" class="text-center onPreview-video">
                        <video controls>
                          <source :src="dialogBannerUrl" type="video/mp4">
                        </video>
                      </div>
                      <div v-else class="text-center">
                        <img :src="dialogBannerUrl" fit="fill" />
                          <!-- <template #placeholder>
                            <div class="image-slot">Loading<span class="dot">...</span></div>
                          </template> -->
                      </div>
                    </el-dialog>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </template>

          
          <!-- <hr v-if="postForm.post_banner.length || uploadBannerLoading" /> -->

          <div class="d-flex justify-content-between">
            <ul class="post-opt-block d-flex list-inline m-0 p-0 flex-wrap">
              <!-- 圖像 -->
              <el-upload :show-file-list="false" :headers="{'authorization':token}"
                :accept="commomData.uploadOption.accept" :data=" { 'type': 'post_media' }"
                :action="commomData.uploadOption.url" :on-success="handleAvatarSuccess"
                :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :on-progress="handleProgress"
                :before-upload="handleBeforeUpload" :multiple="true">
                <el-tooltip class="item" effect="dark" :content="`${$t('label.uploadImage')}${$t('label.or')}${$t('label.uploadVideo')}`" placement="bottom">
                  <li class="mb-md-0">
                    <button type="button" class="btn btn-circle-sm mb-1 me-1">
                      <i class="far ri-image-line fa-fw me-0"></i>
                    </button>
                  </li>
                </el-tooltip>
                <!-- <el-tooltip class="item" effect="dark" :content="`${$t('label.uploadImage')}${$t('label.or')}${$t('label.uploadVideo')}`" placement="bottom">
                  <li class="mb-md-0">
                    <button type="button" class="btn btn-circle-sm mb-1 me-1">
                      <i class="far ri-video-line fa-fw me-0"></i>
                    </button>
                  </li>
                </el-tooltip> -->
                <!-- <el-tooltip class="item" effect="dark" :content="$t('label.uploadVideo')" placement="bottom">
                </el-tooltip> -->
              </el-upload>
              <li class="mb-md-0 " title="媒體庫圖片">
                <button type="button" class="btn btn-circle-sm mb-1 me-1 hide_input" @click="openMediaModal">
                  <!-- <i class="far ri-image-line fa-fw me-0"></i> -->
                  <img src="@/assets/images/media.png" style="width: 20px;height: 20px;" alt="">
                </button>
              </li>
              <!-- <li class="mb-md-0 ">
                <button type="button" class="btn btn-circle-sm mb-1 me-1 hide_input" @click="hhhh">
                  <i class="las la-calendar fa-fw me-0"></i>
                </button>
              </li> -->
            </ul>
            <el-dropdown split-button type="primary" @command="onHandleCommand">
              {{selectedProgramText}}
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="-4">
                    <el-icon>
                      <i class="ri-earth-line"></i>
                    </el-icon>
                    {{ $t('label.publicPost') }}
                  </el-dropdown-item>
                  <el-dropdown-item command="-1">
                    <el-icon>
                      <i class="ri-focus-3-line"></i>
                    </el-icon>
                    {{ $t('label.fundingTarget') }}
                  </el-dropdown-item>
                  <!-- <el-dropdown-item command="-5">
                    <el-icon>
                      <i class="ri-earth-line"></i>
                    </el-icon>
                    {{ $t('label.recommendPost') }}
                  </el-dropdown-item> -->
                  <el-dropdown-item command="-6">
                    <el-icon>
                      <i class="ri-earth-line"></i>
                    </el-icon>
                    {{ $t('label.single_unlock') }}
                  </el-dropdown-item>
                  <template v-if="settions?.program">
                    <el-dropdown-item v-for="(item, index) in settions.program" :key="index" :command="item">
                      <el-icon>
                        <i class="ri-money-dollar-circle-line"></i>
                      </el-icon>
                      {{item.program_title}}
                    </el-dropdown-item>
                  </template>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <!-- 選擇日期發文 -->
         
         <hr v-if="showDateTime">
         <div v-if="showDateTime" class="d-flex justify-content-center">
          <div class="col-md-6">
            <div class="input-group date">
              <div class="input-group date">
                <input v-model="valueDateTime.selectDate" id="selectDate" name="selectDate" type="date" class="form-control timePicker">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group date">
              <div class="input-group date">
                <input v-model="valueDateTime.selectTime" id="selectTime" name="selectTime" type="time" required class="form-control timePicker">
              </div>
            </div>
          </div>
             <!-- <div class=" text-primary">
            <button @click="hhhh" type="button" class="btn btn-circle-sm">
              <i class="far ri-close-circle-line text-primary fa-2x"></i>
            </button>
          </div> -->
         </div>
          <!-- 計畫為，定時貼文 -->
          <hr v-if="isPlan">
          <IsPlan :isPlan="isPlan" :planDate="planDate"></IsPlan>
          <!-- 圖像-->
          <hr v-if="postForm.post_media.length || selectMedia.length || uploadLoading" />
          <div v-if="postForm.post_media.length || selectMedia.length || uploadLoading">
            <div>
              <div>
                <!-- {{message_template_media}} -->
                <ul class="el-upload-list el-upload-list--picture-card">
                  <!-- upload Percent -->
                  <li v-for="(m,index) in postForm.post_media" :key="index" class="el-upload-list__item is-success"
                    tabindex="0">
                    <img class="el-upload-list__item-thumbnail" :src="m.media_url" alt="">
                    <label class="el-upload-list__item-status-label">
                      <i class="el-icon el-icon--upload-success el-icon--check">
                        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                          <path fill="currentColor"
                            d="M406.656 706.944 195.84 496.256a32 32 0 1 0-45.248 45.248l256 256 512-512a32 32 0 0 0-45.248-45.248L406.592 706.944z">
                          </path>
                        </svg>
                      </i>
                    </label>
                    <i class="el-icon el-icon--close">
                      <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor"
                          d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                        </path>
                      </svg>
                    </i>
                    <span class="el-upload-list__item-actions">
                      <span class="el-upload-list__item-preview">
                        <i @click="onPreview(m)" class="el-icon el-icon--zoom-in">
                          <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor"
                              d="m795.904 750.72 124.992 124.928a32 32 0 0 1-45.248 45.248L750.656 795.904a416 416 0 1 1 45.248-45.248zM480 832a352 352 0 1 0 0-704 352 352 0 0 0 0 704zm-32-384v-96a32 32 0 0 1 64 0v96h96a32 32 0 0 1 0 64h-96v96a32 32 0 0 1-64 0v-96h-96a32 32 0 0 1 0-64h96z">
                            </path>
                          </svg>
                        </i>
                      </span>
                      <span @click="onDelete(index)" class="el-upload-list__item-delete">
                        <i class="el-icon el-icon--delete">
                          <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor"
                              d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32zm192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32z">
                            </path>
                          </svg>
                        </i>
                      </span>
                    </span>
                  </li>
                  <li v-for="(m,index) in selectMedia" :key="`media${m.media_id}`" class="el-upload-list__item is-success"
                    tabindex="0">
                    <img class="el-upload-list__item-thumbnail" :src="m.media_square_url" alt="">
                    <label class="el-upload-list__item-status-label">
                      <i class="el-icon el-icon--upload-success el-icon--check">
                        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                          <path fill="currentColor"
                            d="M406.656 706.944 195.84 496.256a32 32 0 1 0-45.248 45.248l256 256 512-512a32 32 0 0 0-45.248-45.248L406.592 706.944z">
                          </path>
                        </svg>
                      </i>
                    </label>
                    <i class="el-icon el-icon--close">
                      <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor"
                          d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                        </path>
                      </svg>
                    </i>
                    <span class="el-upload-list__item-actions">
                      <span class="el-upload-list__item-preview">
                        <i @click="onMediaPreview(m)" class="el-icon el-icon--zoom-in">
                          <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor"
                              d="m795.904 750.72 124.992 124.928a32 32 0 0 1-45.248 45.248L750.656 795.904a416 416 0 1 1 45.248-45.248zM480 832a352 352 0 1 0 0-704 352 352 0 0 0 0 704zm-32-384v-96a32 32 0 0 1 64 0v96h96a32 32 0 0 1 0 64h-96v96a32 32 0 0 1-64 0v-96h-96a32 32 0 0 1 0-64h96z">
                            </path>
                          </svg>
                        </i>
                      </span>
                      <span @click="onMediaDelete(index)" class="el-upload-list__item-delete">
                        <i class="el-icon el-icon--delete">
                          <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor"
                              d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32zm192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32z">
                            </path>
                          </svg>
                        </i>
                      </span>
                    </span>
                  </li>
                  <li v-if="uploadLoading" v-loading="uploadLoading" class="el-upload-list__item is-success"
                    tabindex="0">
                    <div class="text-primary text-center" style="font-size: 16px; z-index: 99999;">
                      <div class="p-1">{{uploadPercent}}</div>
                    </div>
                  </li>
                </ul>
                <el-dialog v-model="dialogVisible" width="80%">
                  <div v-if="dialogType === 'video'" class="text-center onPreview-video">
                    <video controls>
                      <source :src="dialogUrl" type="video/mp4">
                    </video>
                  </div>
                  <div v-else class="text-center">
                    <img :src="dialogUrl" fit="fill" />
                      <!-- <template #placeholder>
                        <div class="image-slot">Loading<span class="dot">...</span></div>
                      </template> -->
                  </div>
                </el-dialog>
              </div>
            </div>
          </div>
          <!-- <div>圖像</div> -->
          <!-- 籌資目標-->
          <hr v-if="isTarget" />
          <div v-if="isTarget" class="d-flex justify-content-between">
            <div class="pt-1">{{$t('籌資目標')}} </div>
            <div>
              <input type="number" v-model="postForm.post_target" class="form-control" :placeholder="$t('設定籌資目標金額')">
            </div>
          </div>
          <!-- 單篇解鎖-->
          <hr v-if="isDonate" />
          <div v-if="isDonate" class="d-flex justify-content-between">
            <div class="pt-1">{{$t('label.single_unlock')}} </div>
            <div>
              <input type="number" v-model="postForm.post_donate" class="form-control" :placeholder="$t('設定單篇解鎖金額')">
            </div>
          </div>
           <!-- 單篇解鎖 選擇是否為限制級內容-->
          <hr v-if="isDonate" />
          <div v-if="isDonate" class="d-flex justify-content-end align-items-center">
            <!-- <div class="form-check pt-1"> -->
              <label class="form-check-label me-2" for="flexCheckDefault">
                {{$t('label.district')}}
              </label>
              <input class="form-check-input mt-0" type="checkbox" v-model="postForm.post_underage" id="flexCheckDefault">
            <!-- </div> -->
          </div>
          <hr />
          <button :disabled="uploadBannerLoading || uploadLoading" class="btn btn-primary d-block w-100 mt-3"
            @click="onEditPost()">
            {{$t('編輯')}}
          </button>


          <modal ref="media_modal" id="media_modal" dialogClass="modal-fullscreen-lg-down" tabindex="-1" title="選擇媒體庫圖片" aria-labelledby="modalsLabel" aria-hidden="true">
            <model-header>
              <h5 class="modal-title" id="modalsLabel">選擇媒體庫圖片</h5>
              <button :dismissable="true" class="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close" @click="cancelMediaImg">
                <i class="ri-close-fill"></i>
              </button>
            </model-header>
            <model-body style="max-height: 77vh;overflow-y: scroll;">
              <div v-if="profileImages.length >=1" class="row images">
                <div class="col-4 mb-2 col-lg-3 col-md-4" v-for="(item, index) in profileImages" :key="`profileImages${index}`">
                  <div @click="selectMediaImg(item)" class="user-images position-relative overflow-hidden">
                    <a href="javascript:void(0)" style="position: relative;display: block;">
                      <img :src="item.media_square_url" :data-source="item.media_url" class="img-fluid rounded" />
                      <img v-if="selectMedia.some(innerItem => innerItem.media_id === item.media_id)" src="@/assets/images/check_img.png" class="check_img" alt="">
                    </a>
                  </div>
                </div>
                </div>
                <div v-else class="card card-block card-stretch card-height">
                <div class="card-body text-center">{{ $t('label.noInformation') }}</div>
                </div>
            </model-body>
            <!-- <model-footer> -->
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" :dismissable="true" data-bs-dismiss="modal" aria-label="Close" @click="cancelMediaImg">{{ $t('label.cancel') }}</button>
                <button type="button" class="btn btn-primary" @click="media_modal.hide()">{{ $t('button.confirm') }}</button>
              </div>
            <!-- </model-footer> -->
          </modal>
  </el-dialog>
</template>

<script>
import {
  reactive,
  getCurrentInstance,
  ref,
  computed,
  onMounted,
  watch
} from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import { getSettings } from '@/api/settings'

import { getPostById, updatePost } from '@/api/post'
import { required, minLength } from '@vuelidate/validators'
import { commomData } from '@/utils/commonVmodel'
import fileUtil from '@/utils/index.js'
import { getMedia } from '@/api/media'

export default {
  name: 'EditPost',
  props: ['isCalendar'],
  setup(props) {
    const { t } = useI18n({ useScope: 'global' })
    const { proxy } = getCurrentInstance()
    const v$ = useVuelidate()
    const $toast = proxy.$toast
    const $moment = proxy.$moment

    const store = useStore()
    const selectedProgramText = ref(t('label.publicPost'))
    const isTarget = ref(false)
    const isDonate = ref(false)
    const dialogVisible = ref(false)
    const uploadLoading = ref(false)
    const uploadPercent = ref('0%')
    const dialogUrl = ref('')
    const dialogType = ref('')
    const mediaList = ref([])
    const settions = ref({})
    const innerWidth = computed(() => window.innerWidth)

    // upload banner 單篇解鎖
    const dialogBannerVisible = ref(false)
    const uploadBannerLoading = ref(false)
    const uploadBannerPercent = ref('0%')
    const dialogBannerUrl = ref('')
    const dialogBannerType = ref('')
    const isChangeBanner = ref(false)

    // 媒體庫
    const media_modal = ref(false)
    const selectMedia = ref([])
    const profileImages = ref([])

    const createFormData = () => ({
      post_content: '',
      post_media: [],
      post_type: 4,
      post_target: 100,
      post_donate: 0,
      program_id: 0,
      post_queue_time: '',
      post_banner: [],
      post_underage: 0
    })
    const postForm = reactive(createFormData())
    const valueDateTime = ref({
      selectDate: '',
      selectTime: ''
    })
    const isDateTimePickerVisible = ref(false)
    const showDateTime = ref(false)
    const value1 = ref('')
    const dialogEditPostVisible$ = computed(
      () => store.state.User.dialogEditPostVisible
    )
    const user = computed(() => store.state.User.user)
    const dialogEditPostId = computed(() => store.state.User.dialogEditPostId)
    const token = computed(() => store.state.User.token)

    watch(dialogEditPostVisible$, newValue => {
      if (newValue) {
        getUserSettions()
        getPostById(dialogEditPostId.value)
          .then(res => {
            Object.assign(postForm, res.data)

            postForm.post_media_photo.forEach(item => {
              console.log('post_media_photo', item)

              postForm.post_media.push({
                media_id: '',
                media_type: 'photo',
                media_url: item.media_url,
                media_img: item.media_url,
                media_video: '',
                square: item.img,
                thumb: item.thumb,
                img: item.img
              })
            })
            postForm.post_media_video.map(item => {
              console.log('post_media_video', item)
              postForm.post_media.push({
                media_id: '',
                media_type: 'video',
                media_url: item.media_url,
                media_img: item.media_url,
                media_video: item.media_video_url,
                square: item.img,
                thumb: item.thumb,
                img: item.img
              })
            })
            switch (postForm.post_type) {
              case 1:
                isTarget.value = true
                isDonate.value = false
                postForm.post_type = 1
                postForm.program_id = 0
                postForm.post_target = parseInt(postForm.post_target.replaceAll('$', ''))
                postForm.post_donate = 0
                selectedProgramText.value = t('籌資目標')
                break
              case 3:
                isTarget.value = false
                isDonate.value = false
                postForm.post_type = 3
                postForm.post_target = 0
                postForm.post_donate = 0
                selectedProgramText.value = postForm.post_type_name
                break
              case 4:
                isTarget.value = false
                isDonate.value = false
                postForm.post_type = 4
                postForm.program_id = 0
                postForm.post_target = 0
                postForm.post_donate = 0
                selectedProgramText.value = t('label.publicPost')
                break
              case 5:
                isTarget.value = false
                isDonate.value = false
                postForm.post_type = 5
                postForm.program_id = 0
                postForm.post_target = 0
                postForm.post_donate = 0
                selectedProgramText.value = t('label.recommendPost')
                break
              case 6:
                isDonate.value = true
                isTarget.value = false
                postForm.post_type = 6
                postForm.program_id = 0
                postForm.post_target = 0
                postForm.post_underage = postForm.post_underage === 0 ? true : false
                selectedProgramText.value = t('label.single_unlock')

                postForm.post_banner = [postForm.post_banner]
                postForm.post_banner[0].file_url = postForm.post_banner[0].img.media_url
                postForm.post_donate = parseInt(postForm.post_donate.replaceAll('$', ''))
                break
              default:
                break
            }
            if (props.isCalendar) {
              showDateTime.value = 1

              const [datePart, timePart] = postForm.date_time.split(' ')

              valueDateTime.value.selectDate = datePart
              valueDateTime.value.selectTime = timePart
            }
            // console.log(datePart)
            // console.log(timePart)
          })
          .catch(err => {
            console.log(err)
            $toast.show(err.response.data.message)
          })
      } else {
        store.dispatch('User/dialogEditPostIdAction', '')
        Object.assign(postForm, createFormData())
      }
    })
    const hhhh = () => {
      showDateTime.value = !showDateTime.value
      if (!showDateTime.value) {
        valueDateTime.value = ''
        postForm.created_date = ''
        postForm.created_time = ''
      }
    }
    const onEditPost = () => {
      if (postForm.post_content.length === 0) {
        $toast.show(t('文章未完成不能發文'))
        return;
      }
      // 定時貼文改變時間，檢查是否過去的時間
      if (props.isCalendar) {
        debugger
        if (
          $moment(
            `${valueDateTime.value.selectDate} ${valueDateTime.value.selectTime}`
          ).isBefore($moment().format('YYYY-MM-DD HH:mm'))
        ) {
          $toast.show(t('不能選擇過去的時段'))
          return false
        }
      }

      if (postForm.post_banner.length === 0 && postForm.post_type === 6) {
        $toast.show(t('請上傳封面照'))
        return false
      }
      if (postForm.post_donate <= 0 && postForm.post_type === 6) {
        $toast.show(t('單篇解鎖金額為必填'))
        return false
      }

      if (valueDateTime.value) {
        postForm.post_queue_time =
          valueDateTime.value.selectDate + ' ' + valueDateTime.value.selectTime
      }
      if (selectMedia.value.length > 0) {
        postForm.user_media = selectMedia.value.map((item) => {
          return item.media_id
        })
      }
      const d = {
        postForm,
        ...dialogEditPostId.value
      }
      if (postForm.post_type === 6) {
        d.postForm.post_banner = isChangeBanner.value ? d.postForm.post_banner[0].file_path :  d.postForm.post_banner[0]
        d.postForm.post_underage = d.postForm.post_underage ? 0 : 1;
      } else {
        d.postForm.post_banner = ""
        delete d.postForm.post_underage
      }
      d.postForm.post_target =
        d.postForm.post_target === '' ? 0 : d.postForm.post_target
      d.postForm.post_donate =
        d.postForm.post_donate === '' ? 0 : d.postForm.post_donate
      d.postForm.program_id =
        d.postForm.program_id === '' ? 0 : d.postForm.program_id
      // d.postForm.post_media = d.postForm.post_media.filter(
      //   item => item.media_id !== undefined && item.media_id !== null
      // )
      updatePost(d.postForm)
        .then(res => {
          $toast.show(res.message)
          setTimeout(() => {
            location.reload()
          }, 2000)
        })
        .catch(err => {
          $toast.show(err.response.data.message)
        })
    }
    const onHandleCommand = $command => {
      console.log($command)
      if ($command === '-4') {
        actionTarget()
      }
      if ($command === '-1') {
        actionTarget()
      }
      if ($command === '-5') {
        actionTarget()
      }
      switch ($command) {
        case '-1':
          isTarget.value = true
          isDonate.value = false
          postForm.post_type = 1
          postForm.program_id = 0
          postForm.post_target = 0
          postForm.post_donate = 0
          selectedProgramText.value = t('籌資目標')
          break
        case '-4':
          isTarget.value = false
          isDonate.value = false
          postForm.post_type = 4
          postForm.program_id = 0
          postForm.post_target = 0
          postForm.post_donate = 0
          selectedProgramText.value = t('label.publicPost')
          break
        case '-5':
          isTarget.value = false
          isDonate.value = false
          postForm.post_type = 5
          postForm.program_id = 0
          postForm.post_target = 0
          postForm.post_donate = 0
          selectedProgramText.value = t('label.recommendPost')
          break
        case '-6':
          isDonate.value = true
          isTarget.value = false
          postForm.post_type = 6
          postForm.program_id = 0
          postForm.post_target = 0
          postForm.post_donate = 0
          selectedProgramText.value = t('label.single_unlock')
          break
        default:
          isTarget.value = false
          isDonate.value = false
          postForm.post_type = 3
          postForm.post_target = 0
          postForm.post_donate = 0
          postForm.program_id = $command.program_id
          selectedProgramText.value = $command.program_title
          break
      }
      console.log($command)
    }

    const w = computed(() => {
      if (window.innerWidth > 1000 && window.innerWidth < 1200) {
        return '50%'
      } else if (window.innerWidth > 1200 && window.innerWidth < 2000) {
        return '40%'
      } else {
        return window.innerWidth - window.innerWidth / 10 + 'px'
      }
    })
    const actionTarget = () => {
      isTarget.value = !isTarget.value
    }
    const handleRemove = (uploadFile, uploadFiles) => {
      console.log(uploadFile, uploadFiles)
      postForm.value.post_media = postForm.value.post_media.filter(element => {
        const c = !element.message_template_media_url
          ? element.message_template_media_img
          : element.message_template_media_url
        return c !== uploadFile.url
      })
    }

    const handlePictureCardPreview = uploadFile => {
      dialogUrl.value = !uploadFile.url
      dialogVisible.value = true
    }
    const handleAvatarSuccess = (response, uploadFile) => {
      uploadLoading.value = false
      console.log(response, uploadFile)
      const d = {
        // media_id: Date.now(),
        media_id: '',
        media_type: response.file.file_type === 'video' ? 'video' : 'photo',
        media_url: response.file.file_url,
        media_img: response.file.file_path,
        media_video: response.file.file_video_url
          ? response.file.file_video_url
          : '',
        img: response.file.file_path.img,
        square: response.file.file_path.square,
        thumb: response.file.file_path.thumb
      }
      postForm.post_media.push(d)
    }
    const handleProgress = event => {
      console.log('event', event.percent)
      if (event.percent > 1 && event.percent < 100) {
        uploadLoading.value = true
        uploadPercent.value = `${Math.round(event.percent)}%`
      }
    }
    const message_template_media = computed(() => {
      return postForm.value?.post_media.map((item, index) => {
        console.log(index)
        return {
          name: Math.random(),
          url: item.file_url
        }
      })
    })
    const onPreview = m => {
      if (m.media_type === 'video') {
        dialogUrl.value = m.media_video
        dialogType.value = m.media_type
      } else {
        dialogUrl.value = m.media_img
        dialogType.value = m.media_type
      }

      dialogVisible.value = !dialogVisible.value
    }
    const onDelete = index => {
      postForm.post_media.splice(index, 1)
    }
    const handleBeforeUpload = m => {
      console.log('handleBeforeUpload', m)
      uploadLoading.value = true
    }

    // 單篇解鎖function
    const handleBannerRemove = (uploadFile, uploadFiles) => {
      console.log(postForm)
      console.log(uploadFile, uploadFiles)
      // postForm.post_banner = postForm.post_banner.filter(element => {
      //   const c = !element.message_template_media_url
      //     ? element.message_template_media_img
      //     : element.message_template_media_url
      //   return c !== uploadFile.url
      // })
      postForm.post_banner = [];
    }

    const handleBannerPreview = uploadFile => {
      dialogBannerUrl.value = !uploadFile.url
      dialogBannerVisible.value = true
    }
    const handleBannerSuccess = (response, uploadFile) => {
      uploadBannerLoading.value = false
      console.log(response, uploadFile)
      // const d = {
      //   media_id: '',
      //   media_type: response.file.file_type === 'video' ? 'video' : 'photo',
      //   media_url: response.file.file_url,
      //   media_img: response.file.file_path,
      //   media_video: response.file.file_video_url
      //     ? response.file.file_video_url
      //     : '',
      //   img: response.file.file_path.img,
      //   square: response.file.file_path.square,
      //   thumb: response.file.file_path.thumb
      // }
      // postForm.post_banner.push(d)
      postForm.post_banner.push(response.file)
      console.log(postForm.post_banner)
      isChangeBanner.value = true
    }

    const handleBannerProgress = event => {
      console.log('event', event.percent)
      if (event.percent > 1 && event.percent < 100) {
        uploadBannerLoading.value = true
        uploadBannerPercent.value = `${Math.round(event.percent)}%`
      }
    }
    const onBannerPreview = m => {
      if (m.file_type === 'video') {
        dialogBannerUrl.value = m.file_video_url ? m.file_video_url : ''
        dialogBannerType.value = m.file_type
      } else {
        dialogBannerUrl.value = m.file_url
        dialogBannerType.value = m.file_type
      }

      dialogBannerVisible.value = !dialogBannerVisible.value
    }

    const onBannerDelete = index => {
      postForm.post_banner.splice(index, 1)
    }

    const handleBeforeBannerUpload = file => {
      if (postForm.post_banner.length === 1) {
        $toast.show("封面圖片只能上傳一張")
        return;
      }
      console.log('handleBeforeUpload', file)
      uploadBannerLoading.value = true

      if (file.type === 'video/mp4' || file.type === 'video/ogg' || file.type === 'video/flv' || file.type === 'video/rmvb'  || file.type === 'video/mov') {
        console.log('type is video')
      } else {
        return new Promise((resolve) => {
          fileUtil.getOrientation(file).then((orient) => {
            if (orient && orient === 6) {
              let reader = new FileReader()
              let img = new Image();
              reader.onload = (e) => {
                img.src = e.target.result
                img.onload = function () {
                  const data = fileUtil.rotateImage(img, img.width, img.height)
                  const newFile = fileUtil.dataURLtoFile(data, file.name)
                  resolve(newFile)
                }
              }
              reader.readAsDataURL(file)
            } else {
              resolve(file)
            }
          })
        })
      }
    }

    const getUserSettions = () => {
      getSettings()
        .then(res => {
          settions.value = res
        })
        .catch(err => {
          console.log(err)
        })
    }

    const openMediaModal = () => {
      media_modal.value.show()
    }

    const getMediaList = () => {
      const obj = {
        sort_by: 'desc',
        order_by: 'created_at',
        page_size: 999,
        page: 1,
        media_category_id: -1,
        // type: 'photo',
      }

      getMedia(obj)
        .then(res => {
            profileImages.value = res.data
        })
        .catch(() => {
          // $toast.show(err.response.data.message)
        })
    }

    const selectMediaImg = (obj) => {
      if (selectMedia.value.some(item => item.media_id === obj.media_id)) {
        selectMedia.value = selectMedia.value.filter(item => item.media_id !== obj.media_id)
        return
      }
      selectMedia.value.push(obj)
    }

    const cancelMediaImg = () => {
      selectMedia.value = []
    }

    const onMediaPreview = m => {
      if (m.media_type === 'video') {
        dialogUrl.value = m.media_video
        dialogType.value = m.media_type
      } else {
        dialogUrl.value = m.media_square_url
        dialogType.value = m.media_type
      }

      dialogVisible.value = !dialogVisible.value
    }

    const onMediaDelete = index => {
      selectMedia.value.splice(index, 1)
    }

    onMounted(() => {
      getUserSettions()
      getMediaList()
    })

    return {
      w,
      dialogEditPostVisible$,
      isDateTimePickerVisible,
      handleBeforeUpload,
      onPreview,
      onDelete,
      v$,
      postForm,
      $toast,
      onHandleCommand,
      selectedProgramText,
      isTarget,
      actionTarget,
      handlePictureCardPreview,
      handleAvatarSuccess,
      message_template_media,
      handleRemove,
      mediaList,
      dialogVisible,
      handleProgress,
      uploadLoading,
      uploadPercent,
      dialogUrl,
      dialogType,
      onEditPost,
      createFormData,
      t,
      settions,
      valueDateTime,
      hhhh,
      showDateTime,
      value1,
      user,
      token,
      innerWidth,
      isDonate,
      dialogBannerVisible,
      uploadBannerLoading,
      uploadBannerPercent,
      dialogBannerUrl,
      dialogBannerType,
      handleBannerRemove,
      handleBannerPreview,
      handleBannerSuccess,
      handleBannerProgress,
      onBannerPreview,
      onBannerDelete,
      handleBeforeBannerUpload,
      openMediaModal,
      media_modal,
      selectMedia,
      profileImages,
      selectMediaImg,
      cancelMediaImg,
      onMediaPreview,
      onMediaDelete
    }
  },
  validations() {
    return {
      postForm: {
        user_name: { required },
        account: { required, minLength: minLength(6) }
      }
    }
  },
  data() {
    return {
      commomData: commomData()
    }
  },
  methods: {
    closed() {
      this.$store.dispatch('User/dialogEditPostAction', false)
    }
  }
}
</script>

<style scoped>
.upload_wrap {
  width: 31.5%;
  height: 90px;
  min-width: 90px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.check_img {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px !important;
  height: 30px;
  background: rgba(255, 255, 255, .9);
}
/* .el-dialog img {
  width: 60%;
} */
</style>
